<template>
    <div class="pickUpMembers">
        <el-dialog title="查询会员基本信息" :visible.sync="dialogFormVisible" top="60px">
            <el-card shadow="never" style="margin-top: 8px">
                <el-form @submit.native.prevent :inline="true" v-model="form" label-width="80px">
                    <el-form-item>
                        <el-button type="primary" @click="searchHandleQuery">查询</el-button>
                    </el-form-item>
                    <el-form-item label="查询条件">
                        <el-input placeholder="会员名称/手机号" v-model="form.search" />
                    </el-form-item>
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    id="pickUpMembersTable"
                    border
                    ref="pickUpMembersTable"
                    stripe
                    style="width: 100%"
                    :data="tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="480"
                >
                    <el-table-column width="60" type="selection" />
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column prop="name" label="会员名称" width="180" />
                    <el-table-column prop="mobile" label="手机号" width="120" />
                    <el-table-column prop="sex" label="性别" width="120">
                        <template slot-scope="scope"
                            ><span>{{ scope.row.sex | sex }}</span></template
                        >
                    </el-table-column>
                    <el-table-column prop="createTime" label="注册时间" width="140" />
                    <el-table-column prop="regChannel" label="注册渠道" width="120">
                        <template slot-scope="scope"
                            ><span>{{ scope.row.regChannel | regChannel }}</span></template
                        >
                    </el-table-column>
                </el-table>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    :current-page="form.page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="ok">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../js/Util';

export default {
    name: 'PickUpMembers',
    data() {
        return {
            form: {
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/member/page',
            },
            loading: false,
            dialogFormVisible: false,
        };
    },
    mounted() {
        this.handleQuery();
    },
    filters: {
        regChannel(regChannel) {
            if (typeof regChannel == 'undefined') {
                return '';
            }
            if (regChannel == '8') {
                return '后台新建';
            }
            if (regChannel == '10') {
                return '会员初始化';
            }
        },
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            } else if (sex == '1') {
                return '女';
            }
        },
    },
    watch: {
        dialogFormVisible() {
            if (this.dialogFormVisible) {
                this.form.search = '';
                this.form.page = 1;
                this.form.limit = Util.Limit;
                this.handleQuery();
            }
        },
    },
    methods: {
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            _this.loadingStart();
            const _params = { params: _this.form };
            Util.queryTableFlag(_this, _this.url.page, _params, false);
        },
        //公共接口
        ok() {
            this.$emit('ok', this.$refs['pickUpMembersTable'].selection);
            this.dialogFormVisible = false;
        },
        show() {
            this.dialogFormVisible = true;
        },
    },
};
</script>
